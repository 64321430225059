define('ember-cp-validations/validators/dependent', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Defines an attribute as valid only if its dependents are valid.
   *
   *  ## Example
   *
   *  ```javascript
   *  // Full name will only be valid if firstName and lastName are filled in
   *  validator('dependent', {
   *    on: ['firstName', 'lastName']
   *  })
   *  ```
   *
   *  @class Dependent
   *  @module Validators
   *  @extends Base
   */
  var Dependent = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var _EmberGetProperties = Ember.getProperties(options, ['on', 'allowBlank']),
          on = _EmberGetProperties.on,
          allowBlank = _EmberGetProperties.allowBlank;

      (false && !(Ember.isPresent(on)) && Ember.assert('[validator:dependent] [' + attribute + '] option \'on\' is required', Ember.isPresent(on)));


      if (Ember.isNone(model)) {
        return true;
      }

      if (allowBlank && Ember.isEmpty(value)) {
        return true;
      }

      var dependentValidations = Ember.getWithDefault(options, 'on', Ember.A()).map(function (dependent) {
        return Ember.get(model, 'validations.attrs.' + dependent);
      });

      if (!Ember.isEmpty(dependentValidations.filter(function (v) {
        return Ember.get(v, 'isTruelyInvalid');
      }))) {
        return this.createErrorMessage('invalid', value, options);
      }

      return true;
    }
  });

  Dependent.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      var dependents = Ember.get(options, 'on');

      (false && !(Ember.isArray(dependents)) && Ember.assert('[validator:dependent] [' + attribute + '] \'on\' must be an array', Ember.isArray(dependents)));


      if (!Ember.isEmpty(dependents)) {
        return dependents.map(function (dependent) {
          return dependent + '.isTruelyValid';
        });
      }

      return [];
    }
  });

  exports.default = Dependent;
});